<template>
  <div class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1>Empresas</h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateCompany = true">
          NOVA EMPRESA
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0 mb-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome da empresa</label>
              <a-input
                placeholder="Escreva o nome"
                v-model="companies.filters.searchTerm"
                @change="getCompanies()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="companies.list"
      :loading="companies.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="companiesTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="companies.pagination.page"
        :total="companies.pagination.total"
        @change="changeCompaniesPage"
        @showSizeChange="changeCompaniesPageSize"
      />
    </div>

    <a-drawer
      title="NOVA EMPRESA"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateCompany"
      @close="openCreateCompany = false"
    >
      <EditCompanyDefaultData
        v-if="openCreateCompany"
        @listCompanies="listCompanies"
      />
    </a-drawer>
  </div>
</template>

<script>
import EditCompanyDefaultData from "@/components/companies/forms/EditCompanyDefaultData.vue";
import companyMixins from "@/mixins/companies/companyMixins.js";

export default {
  name: "ListCompanies",
  components: { EditCompanyDefaultData },
  mixins: [companyMixins],
  data() {
    return {
      openCreateCompany: false,
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 60,
          sorter: true,
        },
        {
          title: "Nome Fantasia",
          dataIndex: "trading_name",
          key: "trading_name",
          scopedSlots: { customRender: "trading_name" },
        },
        {
          title: "Razão Social",
          dataIndex: "company_name",
          key: "company_name",
          scopedSlots: { customRender: "company_name" },
        },
        {
          title: "CNPJ",
          dataIndex: "cnpj",
          key: "cnpj",
          scopedSlots: { customRender: "cnpj" },
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  beforeMount() {
    this.getCompanies();
  },
  methods: {
    listCompanies() {
      this.getCompanies();
      this.openCreateCompany = false;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
  },
};
</script>
